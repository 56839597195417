import queryString from 'querystring';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { Category } from '@ttstr/api';
import { Navigation, useAuth, useProductDetails } from '@ttstr/components';
import MenuPortal from '@ttstr/components/Navigation/MenuPortal';
import UserLoginModal from '@ttstr/components/Navigation/UserLoginModal';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { categories, categoriesReceived } = useProductDetails();
  const { loggedIn, showLogin, setShowLogin, redirectAfterLogin } = useAuth();

  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);

  const [mainMenuRef, setMainMenuRef] = React.useState<HTMLDivElement>(); // Could be put into Context
  const [customerMenuRef, setCustomerMenuRef] = React.useState<HTMLLIElement>(); // Could be put into Context
  const [navbarRef, setNavbarRef] = React.useState<HTMLUListElement>(); // Could be put into Context

  const toggleUserLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const isRoot = React.useMemo(() => {
    const matches = matchPath(location.pathname, { path: '/', exact: true });
    return matches && matches.isExact;
  }, [location.pathname]);

  const selectedCategoryId = qs.categoryId;

  const mainMenu = (
    <>
      <NavItem className="mr-2">
        <a href="https://modular-festival.de/" className="nav-link text-primary">
          zur Website
        </a>
      </NavItem>
      <NavItem className="mr-2">
        <NavLink to="/tickets" exact replace={isRoot} className="nav-link">
          Tickets
        </NavLink>
      </NavItem>
      {/* <NavItem>
        <NavLink to="/products" exact replace={isRoot} className="nav-link">
          Merchandise
        </NavLink>
      </NavItem> */}
      {categoriesReceived &&
        Object.values(categories)
          .sort((a: Category, b: Category) => a.position - b.position)
          .map((c: Category) => (
            <NavItem key={c.id}>
              <NavLink
                to={`/products?categoryId=${c.id}#product-listing`}
                replace={isRoot}
                isActive={() => selectedCategoryId === String(c.id)}
                className="nav-link"
              >
                {c.title}
              </NavLink>
            </NavItem>
          ))}
    </>
  );
  return (
    <header id="header" className="d-print-none sticky-top">
      <Navigation
        logo={require('./assets/images/modular-logo-long.png')}
        // fixed="top"
        cartIcon={<i className="fas fa-shopping-bag" />}
        togglerIcon={
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        }
        togglerClassName="hamburger hamburger--squeeze d-lg-none"
        mainMenuRef={setMainMenuRef}
        customerMenuRef={setCustomerMenuRef}
        navbarRef={setNavbarRef}
        alwaysCloseMainMenu
        mainMenuColumnProps={{
          xs: { order: 1 },
        }}
        mainMenuPosition="right"
        showRegister={false}
        showLanguageSwitcher={true}
      />

      <MenuPortal target={navbarRef}>{mainMenu}</MenuPortal>

      <MenuPortal target={mainMenuRef}>
        <Nav navbar className="d-lg-none">
          {mainMenu}
        </Nav>
      </MenuPortal>

      <MenuPortal target={customerMenuRef}>
        {loggedIn ? (
          <UncontrolledDropdown>
            <DropdownToggle nav caret title={t(`NAVIGATION.ACCOUNT`)} className="text-info">
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/customer/account" exact>
                {t(`CUSTOMER.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/orders" exact>
                {t(`ORDERS.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/landing-page" exact>
                {t(`CUSTOMER.LANDING_PAGE.TITLE`)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={NavLink} to="/customer/logout">
                {t('CUSTOMER.LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavItem tag="div">
            <NavLink
              to="#"
              isActive={() => false}
              onClick={(e) => {
                e.preventDefault();
                toggleUserLoginModal();
              }}
              className="nav-link"
              title={t(`NAVIGATION.ACCOUNT`)}
            >
              <i className="fas fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </NavLink>
          </NavItem>
        )}
        <UserLoginModal
          show={showLogin}
          toggle={toggleUserLoginModal}
          onLoginSuccess={() =>
            redirectAfterLogin && history.push(typeof qs.success === 'string' ? qs.success : '/customer/landing-page')
          }
        >
          <div className="alert alert-default p-0">{t(`LOGIN.INTRO`)}</div>
        </UserLoginModal>
      </MenuPortal>
    </header>
  );
};

export default React.memo(Header);
